<template lang="pug">
  user-form(v-if="user", v-model="user", :newUser="true", @submit="createUser", @cancel="cancel")
</template>

<script>
import UserForm from "@/components/forms/UserForm.vue";
import CreateCompanyUserMutation from "@/graphql/mutations/companies/company_users/CreateCompanyUserMutation.gql";
import CompanyUsersQuery from "@/graphql/queries/CompanyUsers.gql";
export default {
  data() {
    return {
      user: {
        name: null,
        email: null,
        role: null,
        dateFormat: null,
        timezone: null,
        company: {
          id: this.$route.params.id,
        },
      },
    };
  },
  methods: {
    createUser() {
      const vm = this
      const variables = {
        id: this.$route.params.id,
        isExternal: vm.$store.state.isExternal,
        isDeleted: vm.$store.state.isDeleted
      };
      return this.$apollo
        .mutate({
          mutation: CreateCompanyUserMutation,
          variables: {
            input: {
              ...this.user,
            },
          },
          update(store, { data: { createCompanyUser } }) {
            if (createCompanyUser.user.id) {
              const data = store.readQuery({
                query: CompanyUsersQuery,
                variables,
              });
              data.company.users.push(createCompanyUser.user);
              store.writeQuery({
                query: CompanyUsersQuery,
                variables,
                data,
              });
            }
          },
        })
        .then(() => {
          this.$router.push(`/companies/${this.$route.params.id}/users`),
          this.$store.commit('incrementCompanyUserCount'),
            this.$flash.success("The user was successfully created");
        });
    },
    cancel() {
      this.$router.push(`/companies/${this.$route.params.id}/users`);
    },
  },
  components: {
    UserForm,
  },
};
</script>

